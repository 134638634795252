.stepTimer {
  color: darkred;
  font-weight: 800;
  border-radius: 4px;
  text-align: center;
  padding: 2px 4px;
}

.stepTimerText {
}

.stepTimerValue {
}
