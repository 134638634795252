//base element styling

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  position: relative;
}

// headings and body text
h1,
h2,
h3,
h4,
a,
p,
caption,
li {
  color: $purple;
}

h4 {
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 0;
}

h5 {
  font-size: 22px;
  font-weight: 400;
}

p,
a,
li,
span,
caption {
  max-width: 40em;
  margin-top: 0;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 300;
  text-align: left;
}

p {
  margin-bottom: 12px;
}

b {
  font-weight: 700;
}

label {
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 5px;
}

li {
  margin-bottom: 5px;
}

a:link,
a:visited {
  text-decoration: none !important;
  border-bottom: 1px solid $blue;
  color: $blue;

  &[target="_blank"]:after {
    background: url("../assets/external_link.svg") no-repeat 0 100%;
    background-size: 14px 14px;
    display: inline-block;
    width: 16px;
    height: 16px;
    content: "";
    margin-left: 2px;
  }
}

a:hover,
a:active,
a:focus {
  border-bottom: 1px solid $black;
  color: $black;
}

// standard table styling
table {
  width: 100%;
  min-width: 500px;
  border-collapse: collapse;
  font-size: 16px;
}

caption {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 30px;
  border: none;
}

th {
  background-color: $gray;
  font-weight: 400;
  text-align: left;
  padding: 19px 16px;
}

tr {
  min-height: 60px;
}

td {
  font-weight: 300;
  padding: 19px 16px;
}

strong {
  font-weight: bold;
}
