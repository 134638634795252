// margin

$small-margin: 8;
$medium-margin: 16;

// content wrapper to maintain reabability

.contentWrapper {
  max-width: $body-max-width;
  width: 100%;
  padding: 0 4vw;
  margin: 0 auto 5em auto;
}

.dhContentWrapper {
  max-width: $body-max-width;
  width: 100%;
  padding: 0 4vw;
  margin: 0 auto 1em auto;
}
