@import "../../global-styles/index";

.status__image {
  width: 24px;
  height: 24px;
  margin-right: 14px;
}

.sliding__panel {
  visibility: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.sliding__panel__container__active {
  top: 0;
  left: 0;
  //animation-duration: 0.7s;
  //animation-name: slideInRight;
  visibility: visible;
}

.sliding__panel__container__exit {
  top: 0;
  left: 0;
  //animation-duration: 0.7s;
  //animation-name: exit;
  animation-name: fadeout;
  animation-duration: 1s;
  visibility: hidden;
}

.slidingPanelBlockout {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.sliding__panel__content {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 10%;
  width: 90%;
  height: 100%;
  background-color: $white;
  padding: 65px 3% 0 3%;
  overflow: auto;

  h3 {
    font-size: 2rem;
    padding-left: 35px;
  }
}

.success__background {
  background: transparent url(../../assets/ACCC_DigitalInterface_Icons_Approved.svg)
    no-repeat 0 50%;
  background-size: 24px 24px;
}

.failure__background {
  background: transparent url(../../assets/ACCC_DigitalInterface_Icons_Withdrawn.svg)
    no-repeat 0 50%;
  background-size: 24px 24px;
}

.sliding__panel__content__active {
  left: 10%;
  animation-duration: 0.7s;
  animation-name: slideInRight;
  visibility: visible;
}

.sliding__panel__content_exit {
  left: 10%;
  animation-duration: 0.7s;
  animation-name: exit;
}

.sliding__panel__header {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 100%;
  background: $blue;
  width: 90%;
  height: 65px;
  z-index: 1001;

  h2 {
    color: white;
    padding-left: 3%;
    padding-top: 0.4em;
    float: left;
    font-size: 2.4rem !important;
    margin: 0.3em 0 0 0;
  }

  button {
    float: right;
    border: none;
    margin: 0.75em 1em 0 0;
    height: auto;
    min-width: 110px;
  }

  button:hover {
    background-color: $black;
  }
}

.sliding__panel__header__active {
  left: 10%;
  animation-duration: 0.7s;
  animation-name: slideInRight;
  visibility: visible;
}

.sliding__panel__header__exit {
  left: 10%;
  animation-duration: 0.7s;
  animation-name: exit;
  visibility: hidden;
}

.btn__base {
  padding: 1em 1.5em 1em 40px;
  border: 1px solid $gray;
  border-radius: 3px;
  line-height: 1;
  text-align: left;
  margin-bottom: 1em;
  margin-right: 0.5em;
  color: $purple;
  font-size: 1.4rem;
  &:hover,
  &:focus {
    border: 1px solid $blue;
    cursor: pointer;
    background-color: $light-blue;
  }
}

.btn__container {
  position: relative;
}
.btn__copy {
  @extend .btn__base;
  background: white url(../../assets/btn-copy.svg) no-repeat 15px 45%;
  background-size: 18px 18px;
  @include medium-screen {
    position: absolute;
    right: 1em;
    top: 1em;
  }
}

.btn__previous {
  @extend .btn__base;
  background: white url(../../assets/btn-previous.svg) no-repeat 15px 45%;
  background-size: 18px 18px;
}

.btn__next {
  @extend .btn__base;
  background: white url(../../assets/btn-next.svg) no-repeat 15px 45%;
  background-size: 18px 18px;
  float: right;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes exit {
  from {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeout {
  from {
    visibility: visible;
    opacity: 1;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}
