@import "../../global-styles/index";

.actionBar {
  position: fixed;
  bottom: 0;
  background-color: $gray;
  width: 100%;
  height: auto;
  display: block;
  &__scenarioButton {
    margin-right: 0px;

    @media screen and (max-width: 534px) {
      margin-bottom: 10px;
    }
  }

  .contentWrapper {
    padding: 2em 4vw 1em 4vw;
    margin: 0 auto 10px auto;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 896px) {
      align-items: center;
    }

    @include smallest-screen {
      flex-direction: column;
    }
  }
}

.actionBar__actionButtonWrapper {
  display: flex;
  align-items: left;
  @media screen and (max-width: 896px) {
  }
  @include smallest-screen {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  button {
    margin-right: 10px;
  }
}

.actionBar__status {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  color: $black;

  @include smallest-screen {
    margin: 15px 0;
  }
}

.actionBar__statusImage {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  background: $gray;
  content: "";

  &[src=""] {
    display: none;
  }
}

.actionBar__buttonLabel {
  margin-right: 15px;
}

.scrollbarArrowSpace {
  width: 30px;
}
