@import "../../global-styles/index";

.scenarioWrapper {
  margin-bottom: 120px;
}

.centeredText {
  text-align: center;
}

.testplan__buttons {
  margin-top: 10px;

  button {
    margin-right: 15px;
  }
}

.scenarios__table {
  max-width: 1152px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
