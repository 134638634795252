// Base theme
$blue: #0d45d6;
$light-blue: #e7ecfb;
$green: #189f7b;
$red: #e11432;
$orange: #ffcb4d;
$yellow: #fffbce;

$purple: #262b4f;

$white: white;
$gray: #eeeef1;
$med-gray: #999999;
$light-gray: #666666;
$dark-gray: #444444;
$black: #222222;
