@import "../../global-styles/index";

.selectBrand {
  margin-top: 5em;
}

.selectBrand__options {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  @media screen and (min-width: 45em) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
