@import "../../global-styles/index";

.subHeader {
  background-color: $light-blue;
  padding: 2em 0;
  margin-bottom: 2em;
  display: block;

  .contentWrapper {
    margin: 0 auto;

    p {
      max-width: auto;
      padding: 0;
      margin: 0;
    }
  }

  .subtitle {
    font-weight: 400;
  }
}
