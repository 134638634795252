.table {
  margin: 15px 0px;
}

.table__status {
  display: flex;
  align-items: center;
}

.table__info {
  display: flex;
  align-items: center;
}

.table__label {
  margin-right: 5px;
}

.tooltip__icon {
  width: 20px;
  height: 20px;
}

.long-paragraph {
  max-width: 100%;
}
