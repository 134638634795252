@import "../../global-styles/index";

.arrow {
  color: $purple;
}

.breadcrumbs {
  margin-top: 0;
}

.breadcrumbs a,
.arrow {
  margin-right: 10px;
}
