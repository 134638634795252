@import "../../global-styles/index";

.table {
  margin: 0px 0px 15px 0px;
}

.table__status {
  display: flex;
  align-items: center;
}

.table__info {
  display: flex;
  align-items: center;
}

.table__label {
  margin-right: 5px;
}

.tooltip__icon {
  width: 20px;
  height: 20px;
}

.competencyStep {
  background-color: #f5f7fd;
}

.competencyStatus {
  width: 200px;
}

.competenciesHeader {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.competenciesToggle {
  font-size: 18px;
  font-weight: 400;
  margin-top: 3px;
  width: 190px;
}

.competenciesToggle__expand {
  background: url("../../assets/plus-circle.svg") no-repeat 0 50%;
  color: $black;
  background-size: 20px 20px;
  padding: 8px 0px 8px 28px;
}

.competenciesToggle__collapse {
  background: url("../../assets/minus-circle.svg") no-repeat 0 50%;
  color: $black;
  background-size: 20px 20px;
  padding: 8px 0px 8px 28px;
}

.competenciesToggle:hover {
  cursor: pointer;
}

.long-paragraph {
  max-width: 100%;
}
