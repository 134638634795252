@import "../../global-styles/index";

.header {
  background: $blue;
  padding: 0.25rem 0 2.5rem 0;
  @include medium-screen {
    padding: 0.25rem 0 4rem 0;
  }
  @include large-screen {
    padding: 0.25rem 0 2.5rem 0;
  }
}

.header__container {
  max-width: $body-max-width;
  margin: 0 auto;
  width: 100%;
  position: relative;

  &:focus {
    outline: none;
  }

  button {
    border: none;
    height: auto;
    min-width: 110px;
    margin: 0 0 0 10px;
    padding-right: 37px;
    background: transparent url(../../assets/ico-signout-white.svg) no-repeat 85% 50%;
    background-size: 18px 18px;
    float: right;
    margin: 10px 5px 0 0;
    @include medium-screen {
      margin: 1.5em 1em 0 0;
    }
  }

  button:hover,
  button:focus {
    background-color: $black;
    background: $black url(../../assets/ico-signout-white.svg) no-repeat 85% 50%;
    background-size: 18px 18px;
  }
}

.logo {
  width: 90px;
  float: left;
  margin: 1.4rem 2.5rem 0 4vw;
  @include medium-screen {
    margin: 2.2rem 2.7rem 0 4vw;
    width: 125px;
  }
  @include large-screen {
    margin: 1.75rem 3rem 0 4vw;
    width: 153px;
  }
}

.heading__main,
.heading__sub {
  color: $white;
  font-weight: 300;

  &:focus {
    outline: 0;
  }
}

.heading__main {
  font-size: 2rem;
  font-weight: 500;
  margin: 1.6rem 0 0 0;
  display: inline-block;
  @include medium-screen {
    font-size: 2.5rem;
    margin: 2.6rem 0 0 0;
  }
  @include large-screen {
    font-size: 3rem;
    margin: 2.5rem 0 1rem 0;
  }
}

.heading__version {
  font-weight: 300;
}

.heading__sub {
  margin-top: 0px;
}

// Skip link

.skipLink:link,
.skipLink:visited {
  background: $blue;
  color: $white;
  font-weight: 700;
  left: 4vw;
  padding: 10px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
}

.skipLink:hover,
.skipLink:active,
.skipLink:focus {
  background: $purple;
  transform: translateY(0%);
}
