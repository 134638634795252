.toTheTopButton {
  display: none;
  position: fixed;
  bottom: 15px;
  right: 40px;
  height: 60px;
  z-index: 1;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 0.5;
}

@media only screen and (min-width: 1350px) {
  .toTheTopButton {
    bottom: 100px;
    right: 10px;
  }
}

.toTheTopButton:hover {
  filter: invert(50%) sepia(50%) saturate(100%) hue-rotate(0deg) brightness(100%)
    contrast(119%);
}
