@import "../../global-styles/index";
.card__button {
  background-color: #fff;
  padding: 0;
  border: 1px solid $black;
  border-radius: 0 0 3px 3px;
  border-top: 7px solid #262b4f;
  width: 100%;
  min-height: 150px;
  height: auto;
  transition: all 200ms ease-in;
  margin-bottom: 15px;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: #e7ecfb;
    border: 1px solid $blue;
    border-top: 7px solid $blue;
  }

  &:nth-child(odd) {
    margin-right: 4%;
  }

  @media screen and (min-width: 45em) {
    width: 44%;
    margin-bottom: 35px;
  }
}

.card__content {
  padding: 1.5em 2em 1.5em 2em;
  margin: 0;
  display: block;
  text-align: left;
  color: #3c454c;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
}

.card__type {
  text-transform: uppercase;
  font-size: 1.3rem;
  margin-bottom: 0;
  font-weight: 300;
}

.card__name {
  font-size: 2.2rem;
  font-weight: 700;
}

.card__sector {
  font-size: 1.5rem;
  font-weight: 500;
}
