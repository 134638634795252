@import "../../global-styles/index";

.log__container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

.runScenarioButton {
  position: absolute;
  right: 0;
  top: 10px;
}

.scenario__loading {
  height: 50vh;
  display: flex;
  justify-content: center;
}

.scenario__actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-top: 15px;
  justify-content: flex-end;
}

.scenario__action {
  margin-bottom: 0;
  border-bottom: 0;

  button {
    margin-bottom: 0;
  }
}

.alert__generated {
  font-size: 20px;
  font-weight: 600;
}

.alert__generatedSuccess {
  color: $green;
}

.alert__generatedFailure {
  color: $red;
}

.centeredText {
  text-align: center;
}

.scenarioWrapper {
  margin-bottom: 120px;
}
