@import "../../global-styles/index";

.alert__container {
  width: 100%;
  border: 1px solid $gray;
  margin: 30px auto 15px;
  padding: 36px 33px 30px 33px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: top;
}

.alert__topBorder {
  height: 8px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;

  &Success {
    background: $green;
  }

  &Failure {
    background: $red;
  }

  &Warning {
    background: $orange;
  }
}

.alert__content {
  margin-left: 32px;
  h3 {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 10px 0;
    padding: 0;
  }

  &:focus {
    outline: border 2px solid red;
  }
}

.alert__generated {
  font-size: 20px;
  font-weight: 700;

  &Success {
    color: $green;
  }

  &Failure {
    color: $red;
  }
}

.alert__icon {
  width: 75px;
  height: 75px;
}
