@import "../../global-styles/index";

.modal__wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(216, 216, 216, 0.8);
  top: 0;
  right: 0;
  z-index: 20;
}

.modal {
  background: white;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top: 8px solid $orange;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: 500px;
  max-width: 95vw;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modal__content {
  font-size: 20px;
  text-align: center;
  font-weight: 700;
  padding: 0 70px;
}

.modal__main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__heading {
  margin: 15px auto;
  font-size: 22px;
}

.modal__buttons {
  margin-top: 15px;
}

.button__cancel {
  border-bottom: 0;
  cursor: pointer;
  margin-right: 25px;
  color: $blue;
  font-size: 16px;
  background: none;
  border: none;
  text-decoration: underline;

  &:hover,
  &:visited {
    color: $black;
  }
}

.modal__hidden {
  display: none;
}

.icon {
  width: 60px;
  height: 60px;
}
