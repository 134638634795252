@import "../../global-styles/index";

.table__container {
  width: 100%;
  margin-bottom: 15px;
}

.table__wrapper {
  overflow-x: auto;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 11px;
}
thead,
tbody {
  border: 2px solid $gray;
}

.table__link {
  color: $blue;
  text-underline-position: under;
  background: none;
  text-underline-position: under;
  border: none;
  border-bottom: 1px solid blue;
  cursor: pointer;
  padding: 0;
}

.table__reset {
  padding-bottom: 8px;
  text-align: right;
}

.table__status {
  width: 250px;
}

.status {
  display: flex;
  align-items: center;
}

.status__icon {
  width: 25px;
  margin-right: 10px;
}

tr {
  border-bottom: 1px solid $gray;
  &:last-child {
    border-bottom: none;
  }
}

.table__columnName {
  width: 35%;
}
.table__columnStatus {
  width: 20%;
}

.status-info {
  display: block;
  margin-top: 1.5em;
  background: url("../../assets/alert__info__solid.svg") no-repeat 0 0.2em;
  background-size: 20px 20px;
  padding-left: 30px;
}

.noBorder {
  border-bottom: 0;
}
