@import "../../global-styles/index";

.button {
  min-width: 173px;
  height: 50px;
  font-size: 16px;
  background: $purple;
  border: none;
  border-radius: 23px;
  border: 2px solid $purple;
  color: $white;
  padding: 11px;
  cursor: pointer;

  &:hover {
    background: $blue;
    color: $white;
    border: 2px solid transparent;
  }

  &--loading {
    background: $gray;
    color: $dark-gray;
    border: 2px solid $med-gray;

    &:hover {
      cursor: not-allowed;
      background: $gray;
      color: $dark-gray;
      border: 2px solid $med-gray;
    }
  }

  &--secondary {
    background: $white;
    color: $purple;
  }

  &--small {
    min-width: 110px;
    height: auto;
    padding: 10px 15px;
    margin-right: 5px;
  }

  &--disabled,
  &:disabled {
    cursor: not-allowed;
    background: $gray;
    color: $med-gray;
    border: 2px solid $med-gray;

    &:hover {
      background: $gray;
      color: $med-gray;
      border: 2px solid $med-gray;
    }
  }
}
