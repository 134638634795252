@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap");

html {
  font-family: "Lato", sans-serif;
}

h1 {
  font-weight: 700;
  font-size: 4rem;
  line-height: 1.3;
  margin-bottom: 10px;
}

h2 {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 1.25;
  border: none;
}

h3 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 21px;
}
