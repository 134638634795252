@import "../../global-styles/index";

.accordion__section {
  display: flex;
  flex-direction: column;
}

.accordion {
  background: $white;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $gray;
  outline: none;
  margin-bottom: 1em;
}

.accordion:hover,
.accordion:focus,
.active {
  background-color: $light-blue;
}

.accordion__title {
  margin: 0;
  color: $blue;
  font-weight: 600;
}

.accordion__icon {
  margin-left: auto;
  transition: transform 0.2s ease;
  width: 15px;
}

.rotate {
  transform: rotate(180deg);
}

.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 1s ease;
  //margin-top: 30px;
}

.accordion__text {
  padding: 18px;
}
