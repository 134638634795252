@import "../../global-styles/index";

.submit__buttons {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;

  button {
    margin-right: 15px;
  }
}

.submit__page {
  min-height: 100vh;
}

.submit__loading {
  height: 50vh;
  display: flex;
  justify-content: center;
}

.submit__content {
  margin-top: 25px;
}
.submitContent {
  margin-top: 30px;
}

.submit__date {
  font-size: 20px;
  font-weight: 600;
  color: $green;

  &--orange {
    color: $orange;
  }
}

.scenario__counter {
  margin-right: 10px;
}

.centeredText {
  text-align: center;
}
