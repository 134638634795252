@import "../../global-styles/index";

.toast {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: $blue;
  color: $white;
  text-align: center;
  border-radius: 6px;
  padding: 16px;
  position: fixed;
  z-index: 20000;
  left: 50%;
  bottom: 30px;
  font-size: small;
}

.toast__show {
  visibility: visible;
  animation-name: fadein, fadeout;
  animation-delay: 0s, 2.5s;
  animation-duration: 0.5s, 2.5s;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
