@import "../../global-styles/index";

.actionBar {
  position: fixed;
  bottom: 0;
  background-color: $gray;
  width: 100%;
  height: auto;
  display: block;
  &__runScenarioButton {
    margin-right: 10px;
    @media screen and (max-width: 896px) {
      margin-bottom: 10px;
      margin-right: 0px;
    }
  }
  &__backButton {
    background: $white;
    color: $purple;
    margin-right: 20px;

    @include smallest-screen {
      margin-right: 0px;
    }
  }
  .contentWrapper {
    padding: 2em 4vw 1em 4vw;
    margin: 0 auto 10px auto;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 896px) {
      align-items: center;
    }

    @include smallest-screen {
      flex-direction: column;
    }
  }
}

.actionBar__actionButtonWrapper {
  display: flex;
  align-items: center;
  @media screen and (max-width: 896px) {
    flex-direction: column;
  }
}

.actionBar__status {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  color: $black;

  @include smallest-screen {
    margin: 15px 0;
  }
}

.actionBar__statusImage {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  background: $gray;
  content: "";

  &[src=""] {
    display: none;
  }
}

.buttonsWrapper {
  @media screen and (max-width: 896px) {
    max-width: 200px;
  }
  @include smallest-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  button:nth-child(1) {
    @include smallest-screen {
      margin-bottom: 10px;
    }
  }
}

.actionBar__buttonLabel {
  margin-right: 15px;
}
