@import "../../global-styles/index";

.testPlans__intro {
  max-width: 842px;
}

.testPlans__list {
  max-width: 642px;
}

.testPlans__brandName {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 1.33;
  margin-bottom: 1.6rem;
  margin-top: 0;
}

.testPlans__testPlanName {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.33;
  margin-bottom: 0.7em;
}

.testPlans__testPlanIsExtantModal {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.33;
  margin-bottom: 0.7em;
  color: $black;
  text-align: center;
  border: 1px;
}

.testPlans__contactOnboardingModal {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.33;
  margin-bottom: 0.7em;
  color: $black;
  text-align: center;
}

.testPlans__sector {
  font-size: 2rem;
  margin-bottom: 0;
  font-weight: 500;
  margin-bottom: 0.7em;
}

.testPlans__highlight {
  background-color: $light-blue;
  padding: 3em 0 2em 0;
  margin: 0 0 6em 0;
}

.scenarios__table {
  max-width: 1152px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.testplan__buttons {
  margin-top: 10px;

  button {
    margin-right: 15px;
  }
}

.testPlans__brandInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 60em) {
    flex-direction: row;
  }
}

.testPlans__selectedBrand {
  border-left: 5px solid $blue;
  padding-left: 3em;
  margin-top: 2em;
  width: 400px;
}

.testPlans__highlight {
  background-color: $light-blue;
}
