@import "../../global-styles/index";

.log__wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  border-top: 0;
  margin-bottom: 11px;

  table {
    border: 2px solid $gray;
    border-radius: 4px;
  }

  caption {
    display: none;
  }

  .step__button-link {
    background: none;
    border: none;
    position: relative;
    right: auto;
    top: auto;
    color: $blue;
    cursor: pointer;
    border-bottom: 1px solid $blue;
    font-weight: 300;
    padding: 0;

    &:hover {
      color: $black;
      border-bottom: 1px solid $black;
    }

    &:focus {
      //outline: none;
    }

    &:visited {
      color: $black;
      border-bottom: 1px solid $black;
    }
  }
}

.status__image {
  width: 24px;
  height: 24px;
  margin-right: 14px;
}

.step__metaData {
  display: flex;
  flex-direction: column;
}

.step__icon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.step__name {
  vertical-align: middle;
}

.step__status {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.step__date {
  font-size: 14px;
  margin-top: 5px;
}

.step__continueWrapper {
  display: flex;
}

.step--highlighted {
  background: $yellow;
}

.errorDetails {
  color: $red;
}

.interactMessage {
  margin-top: 5px;
}

.log__columnTest {
  width: 40%;
}

.log__columnCompetency {
  min-width: 150px;
}

.log__columnNotes {
}

.log__columnStatus {
  width: 140px;
}

.log__heading--small {
  font-size: 22px;
  margin-bottom: 15px;
}

.srOnly {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.nested {
  display: inline-block;
  padding-left: 40px;
  background: transparent url("../../assets/icon-nested.svg") no-repeat 50% 0;
  background-size: 12px 12px;
  height: 14px;
}

.fontGray {
  color: $light-gray;
}

.groupStep {
  .name {
    font-weight: bold;
  }
}

.logHeader {
  position: relative;
  display: flex;
}

.logHeaderText {
  position: relative;
  flex: 1;
  text-align: left;
}

.logHeaderCollapse {
  text-align: right;
  font-size: 700;
  text-decoration: none;
}

.isNested {
  padding-left: 30px;
}
