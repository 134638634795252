@import "../../global-styles/index";

.submit__date {
  font-size: 20px;
  font-weight: 600;
  color: $green;

  &--orange {
    color: $orange;
  }
}

.submitted__loading {
  height: 50vh;
  display: flex;
  justify-content: center;
}
