// Variables for use in other global styling

// Widths
$body-max-width: 1240px;

// Responsive mixins

@mixin smallest-screen {
  @media (max-width: 533px) {
    @content;
  }
}
@mixin small-screen {
  @media (max-width: 767px) {
    @content;
  }
}
@mixin medium-screen {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin large-screen {
  @media (min-width: 992px) {
    @content;
  }
}
@mixin huge-screen {
  @media (min-width: 1240px) {
    @content;
  }
}
